@use '../custom' as *;

// DO NOT SHOW BOOTSTRAP TOOLTIP FROM MOBILE DEVICES | MODIFY DEFAULT TOOLTIP
@include media-breakpoint-down(lg) {
        
    .tooltip {
        display: none !important;
    }
}

.tooltip .tooltip-inner {background-color: $primary; color: $white;} 
.bs-tooltip-top .tooltip-arrow::before {border-top-color: $primary !important;}
.bs-tooltip-end .tooltip-arrow::before {border-right-color: $primary !important;}
.bs-tooltip-bottom .tooltip-arrow::before {border-bottom-color: $primary !important;}
.bs-tooltip-start .tooltip-arrow::before {border-left-color: $primary !important;}
