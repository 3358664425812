$theme-color-1: #217a81;
$theme-color-2: #7cb4ba;
$theme-color-3: #8CBA8F;
$theme-color-4: #8CBA8F;


// SET THEME COLOR OVERRIDE
$primary: $theme-color-1;
$secondary: $theme-color-2;
$info: $theme-color-3;
$dark: $theme-color-3;

:root {
    // --bs-primary: #217a81;
    // --bs-secondary: #7cb4ba;
    // --bs-info: #8CBA8F;
    // --bs-light: #f8f9fa;
    // --bs-white: #fff;
    // --bs-gray-dark: #343a40;
    --menu-bg: #fff;
    --menu-text: #343a40;
    --menu-hover: #dee2e6;
    --menu-active: #7cb4ba;
    --backToTop: #217a81;
}

.theme-menu,
.theme-footer {
    background-color: var(--menu-bg);
    color: var(--menu-text);
}

.form-check-input:checked {
    background-color: var(--bs-primary) !important;
    border-color: var(--bs-primary) !important;
}

.menuHover {
    background-color: var(--menu-hover);
}
.menuList,
.menuActive {
    background-color: var(--menu-active) !important;
}

.menuHeading ,
.menuBadge {
    background-color: var(--bs-primary) !important;
    color: var(--bs-white) !important;
}

// Disable default grid of .row and .col
// $enable-grid-classes: false;

// Enable CSS grid of .grid and .g-col-*
// $enable-cssgrid: true;

// Font family
$font-family: 'Roboto', sans-serif;

// ACCORDION OVERRIDES
$accordion-padding-y: 1.5rem; // LEAVE THE PADDING-Y TO DEFAULT
$accordion-padding-x: 1.5rem; // LEAVE THE PADDING-X TO DEFAULT
$accordion-icon-color: $primary;
$accordion-icon-active-color: $secondary;
// $accordion-icon-width:                    1.875rem; // LEAVE THE WIDTH TO DEFAULT
$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/> <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/>  <path d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/></svg>");

@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/utilities';

$custom-theme-colors: (
    "altlight":#f2ebfa,
    "altdark": #522192
);

$theme-colors: map-merge($custom-theme-colors, $theme-colors);


// CREATE A NEW UTILITY CLASS TW WITH VALUES 10, 15 AND 20
$utilities: (
    "theme-width":(
        property: width,
        class: tw,
        responsive: true,
        values: (
            10: 10%,
            15: 15%,
            20: 20%,
        )
    ),
    "theme-padding": (
        property: padding,
        class: tp,
        values: (
            15: 1.5rem,
            25: 2.5rem,
            35: 3.5rem,
        )
    ),
    "theme-color": (
        property: color,
        class: ttext,
        state: hover,
        values: (
            "cream": rgb(233, 220, 220),
        )
    ),
    "theme-bg-color": (
        property: background-color,
        class: tbg,
        values: (
            "heading": rgb(195, 204, 213),
            "list": rgb(195, 195, 195),
        )
    ),
    "theme-bg-img": (
        property: background-image,
        responsive: true,
        class: tbg,
        values: (
            "img-0": url('../img/landscape-0.png'),
        )
    ),
);

// IMPORT BOOTSTRAP
@import '../../node_modules/bootstrap/scss/bootstrap';

// IMPORT BOOTSTRAP ICONS
@import '../../node_modules/bootstrap-icons/font/bootstrap-icons.css';