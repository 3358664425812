@use '../custom' as *;

// GRADIENT MIXIN 
@mixin gradient {
    background: linear-gradient(to right, rgba($primary, 0.95), rgba($secondary, 0.95));
}

// ABSOLUTE CENTER 
@mixin absoluteCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// TRANSITION PROPERTY 
@mixin transitionProperty {
    transition-duration: 1s;
    transition-property: all;
}

// Extends
%btn-inset-shadow:hover {
    -webkit-box-shadow: inset 1px 1px 10px #333;
    -moz-box-shadow: inset 1px 1px 10px #333;
    box-shadow: inset 1px 1px 10px #333;
}