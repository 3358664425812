@use '../custom' as *;

a {
	text-decoration: none;
    color: #212529;
}

// .form-check-input {
//   border-radius: 0 !important;
// }

// LOGO
.logo {
  width: 100px
}

// Menu items dark color
// .navbar-light .navbar-nav .nav-link {
//   color: rgba(0, 0, 0, 0.7) !important;
// }
  
html, body {
    height: 100%;
    margin: 0;
  }

  h1 {
	font-weight: 400;
	font-size: 60px;
	line-height: 1.2;
	margin-bottom: 15px;
}
.page-heading {
  background-color: var(--bs-primary) !important;
}
  
.fullHeight {
  height: 100%;
}

.section-top-padding {
  padding-top: 5rem !important;
  // padding-top: 2rem !important;
}

/* ************************************************************************* */
/* REMOVE BUTTON SHADOW AND INPUT SHADOWS. NO MORE OUTLINES IN BOOTSTRAP 5.X */
/* ************************************************************************* */
.form-select:focus,
.dropdown-toggle:active,
.btn-close:focus,
.form-check-input:focus,
.form-control:focus,
.btn:focus,.btn:active:focus,.btn.active:focus,
.clearFocusShadow:focus {
    -webkit-box-shadow:none !important;
    box-shadow: none !important;
}

/* Bootstrap icon sizing and color*/

.bs-icon,
.bs-icon-text {
  color: var(--bs-gray-dark) !important;
}

.bs-icon-fs {
  font-size: 1.8rem !important;
}


.developerImage {
    width: 3rem;
    height:3rem;
}

#backToTop {
  visibility: hidden;
  position: fixed;
  right: 0.5rem;
  bottom: 5.5rem;
  opacity: 0;
  z-index: 1005;
  transition: all 1s ease-in;

  @include media-breakpoint-down(lg) {
    bottom: 7.5rem;
  }
}

#backToTop.show {
  visibility: visible;
  opacity: 1;
}

@include media-breakpoint-down(lg) {
  .advertMobile {
    width: 100%;
    // padding: 0.5rem;
    visibility: hidden;
    position: fixed;
    left: 0;
    // bottom: 3.71rem;
    bottom: 3.31rem;
    opacity: 0;
    transition: all 1s ease-in;
  }
    
  .advertMobile.show {
    visibility: visible;
    opacity: 1;
    transition: all 1s ease-in;
  }

  .advert {
    display: none !important;
  }
}


.pointer {
  cursor: pointer;
}


// Cookies
/* Cookie Notice */
.gdprRemove {
  display: none;
}

.gdpr {
  position: fixed !important;
  // border: 1px solid rgb(149, 149, 149) !important;
  left: 10% !important;
  width: 80% !important;
  z-index: 2000 !important;
  color: rgb(255, 255, 255) !important;
  text-shadow: none !important;
  background: rgb(0, 0, 0) none repeat scroll 0% 0% !important;
  margin: 0px;
  box-shadow: 0 0.1em 0.2em $gray-300 !important;
  opacity: 0.9;
}

.overLay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1999;
  cursor: pointer;
}


.gdpr p {
  width: 95%;
}

.gdpr p,
.gdpr h2 {
  margin: 15px 35px 10px 35px !important;
  text-align: left !important;
  font: 10pt Verdana !important;

}

@media only screen and (max-width: 800px) {
  .gdpr {
      left: 0 !important;
      width: 100% !important;
  }

  .gdpr p,
  .gdpr h2 {
      margin: 0 !important;
      padding: 10px !important;
  }
}

#js__close__gdpr {
  cursor: pointer;
}